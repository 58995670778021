.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.box {
  color: black;
  border-radius:8px;
  /* padding:8px; */
  /* font-size: 1.6rem; */
  width:150px;
  height:90px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: rgb(209,255,251);
  background: linear-gradient(159deg, rgba(209,255,251,1) 0%, rgba(175,210,255,1) 80%);
  box-shadow: 2px 4px 4px #002d66;
  box-shadow: 2px 4px 4px #222;
}
.box-wide {
  border-radius:8px;
  /* padding:8px; */
  /* font-size: 1.6rem; */
  width:648px;
  height:60px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: rgb(209,255,251);
  background: linear-gradient(159deg, rgba(209,255,251,1) 0%, rgba(175,210,255,1) 80%);
  box-shadow: 2px 4px 4px #002d66;
  box-shadow: 2px 4px 4px #222;
}
.box-green {
  background: rgb(242,255,209);
  background: linear-gradient(159deg, rgba(242,255,209,1) 0%, rgba(179,255,175,1) 80%);
}
.box-red {
  background: rgb(255,176,151);
  background: linear-gradient(159deg, rgba(255,176,151,1) 0%, rgba(255,123,123,1) 80%);
}
.box-pink {
  background: rgb(255,151,226);
  background: linear-gradient(159deg, rgba(255,151,226,1) 0%, rgba(180,123,255,1) 80%);
  background: rgb(244,86,224);
  background: linear-gradient(159deg, rgba(244,86,224,1) 0%, rgba(255,128,207,1) 80%);
}
.box-blue {
  background: rgb(45,243,255);
  background: linear-gradient(159deg, rgba(45,243,255,1) 0%, rgba(50,157,255,1) 80%);
}
.box-orange {
  background: rgb(255,119,45);
  background: linear-gradient(159deg, rgba(255,119,45,1) 0%, rgba(255,156,50,1) 80%);
}
.box-purple {
  background: rgb(87,45,255);
  background: linear-gradient(159deg, rgba(87,45,255,1) 0%, rgba(136,50,255,1) 80%);
  background: rgb(173,86,244);
  background: linear-gradient(159deg, rgba(173,86,244,1) 0%, rgba(177,128,255,1) 80%);
}
.box-yellow {
  background: rgb(255,214,45);
  background: linear-gradient(159deg, rgba(255,214,45,1) 0%, rgba(250,255,50,1) 80%);
}
.box-aqua {
  background: rgb(86,244,216);
  background: linear-gradient(159deg, rgba(86,244,216,1) 0%, rgba(128,255,172,1) 80%);
}
.box-blue-2 {
  background: rgb(86,86,244);
  background: linear-gradient(159deg, rgba(86,86,244,1) 0%, rgba(128,177,255,1) 80%);
  background: rgb(86,129,244);
  background: linear-gradient(159deg, rgba(86,129,244,1) 0%, rgba(128,177,255,1) 80%);
  background: rgb(21,92,255);
  background: linear-gradient(299deg, rgba(21,92,255,1) 0%, rgba(0,5,255,1) 80%);
  background: rgb(21,92,255);
background: linear-gradient(299deg, rgba(21,92,255,1) 0%, rgba(100,103,255,1) 80%);
/* background: rgb(21,29,255);
background: linear-gradient(299deg, rgba(21,29,255,1) 0%, rgba(84,87,255,1) 80%); */
background: rgb(255,242,218);
background: linear-gradient(299deg, rgba(255,242,218,1) 0%, rgba(255,248,220,1) 80%);
}
.box-black {
  background: rgb(0,0,0);
  background: linear-gradient(299deg, rgba(0,0,0,1) 0%, rgba(65,65,65,1) 80%);
  color: white;
}
input:not([type="checkbox"]), button {
  all:unset;
}
input:not([type="checkbox"]) {
  background: transparent;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid black;
  margin:4px;
  text-align:left;
}
.border {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid black;
  margin:4px;
}
button {
  background: transparent;
  padding:4px;
  padding-left:16px;
  padding-right:16px;
  border-radius: 8px;
  border: 1px solid black;
  margin:4px;
  text-align:left;
}

.redbutton {
  border: 1px solid red;
  color: red;
}

.greenbutton {
  border: 1px solid green;
  color: green;
}

.grid {
  color: black;
}

@media screen and (prefers-color-scheme: dark) {
  html {
    background-color: #222;
    color: white;
  }

  .border, input:not([type="checkbox"]), button {
    border: 1px solid white;
  }

  .redbutton {
    border: 1px solid #ff4444;
    color: #ff4444;
  }
  
  .greenbutton {
    border: 1px solid lime;
    color: lime;
  }
}